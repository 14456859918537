import css from "./index.sass";
import React from "react";
import Timeline from "~brokerage/components/shared/Timeline";

const SelectTime = ({
  data: {
    unique_id: showingId,
    listing_key: listingKey,
    address,
    listing_photo: { photo_url: photoUrl }
  },
  times,
  showListingRestrictions,
  staticAppointments,
  loadingRestrictions,
  appointmentReason,
  isHandledByThirdParty
}) => {
  const activeShowing = times[0] || {};
  let { startTime, duration } = activeShowing;

  const appt = {
    id: showingId,
    position: 1,
    listingKey: listingKey,
    requestedTimeRaw: startTime,
    address: address,
    photoUrl: photoUrl,
    isHandledByThirdParty: isHandledByThirdParty,
    duration
  };

  return (
    <div className={css.timeline}>
      {!loadingRestrictions && (
        <Timeline
          initiallyActiveListing={appt.listingKey}
          showRestrictions={showListingRestrictions}
          staticAppointments={staticAppointments}
          activeAppointments={[appt]}
          maxHeight={400}
          appointmentReason={appointmentReason}
        />
      )}
    </div>
  );
};

export default SelectTime;
