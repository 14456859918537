import css from "./Footer.sass";
import React, { useContext, useEffect, useState } from "react";
import { ShowingContext } from "~brokerage/components/shared/Timeline/ShowingProvider";
import Button from "~brokerage/components/shared/Button";
import { RiReplyFill } from "@remixicon/react";
import { callApi } from "~brokerage/middlewares/api";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { isForbidden } from "~brokerage/app/helpers/showingConflicts.js";
import { isEmpty } from "lodash";
import { buildCreateShowingPayload, getErrorMessage } from "./utils";
import { BUYER_NAME_APPOINTMENT_TYPES } from "./constants";

const Footer = ({
  steps,
  currentStep,
  setCurrentStep,
  currIndex,
  showable,
  closeModalWindow,
  advanceNoticeDuration,
  disableSameDayRequests,
  appointmentReason,
  buyers,
  buyerAgentPlatformId,
  isBuyerNameRequired,
  selectedBuyerAgent,
  isEditDateTimeOpen
}) => {
  const [disabled, setDisabled] = useState(false);
  const { times } = useContext(ShowingContext);
  const history = useHistory();

  useEffect(() => {
    const isTimeStep = currentStep === "time";
    const hasTime = Boolean(times[0]);

    if (isTimeStep) {
      setDisabled(!hasTime || isForbidden(times[0]));
    } else {
      setDisabled(false);
    }
  }, [currentStep, times]);

  const handleNext = () => {
    if (!showable) {
      closeModalWindow();
      return;
    }
    if (currentStep === "send") {
      createShowing();
    } else {
      setCurrentStep(steps[currIndex + 1].id);
    }
  };

  const createShowing = () => {
    setDisabled(true);
    const payload = buildCreateShowingPayload({
      req: times[0],
      buyerAgentPlatformId,
      appointmentReason,
      buyers
    });

    callApi(`showings`, {}, payload, "post").then(
      response => {
        const { unique_id } = response.data;
        setDisabled(false);
        toast.success("Appointment request has been sent.");
        history.push(`/messages/showing/${unique_id}`);
      },
      error => {
        setDisabled(false);
        toast.error(getErrorMessage(error));
      }
    );
  };

  const convertMinsToHrsMins = mins => {
    let hours = Math.floor(mins / 60);
    let minutes = mins % 60;
    return `${hours} hour(s) and ${minutes} minute(s)`;
  };

  const buyerNameRequired =
    !selectedBuyerAgent &&
    isBuyerNameRequired &&
    isEmpty(buyers) &&
    BUYER_NAME_APPOINTMENT_TYPES.includes(appointmentReason);

  const isHandleNextButtonDisabled =
    disabled ||
    (currentStep === "send" && buyerNameRequired) ||
    isEditDateTimeOpen;

  return (
    <div className={css.footer}>
      {(advanceNoticeDuration > 0 || disableSameDayRequests) && (
        <div className={css.noticeCard}>
          {advanceNoticeDuration > 0
            ? `Notice: This listing requires an advance notice of at least ${convertMinsToHrsMins(
                advanceNoticeDuration
              )}.`
            : "Notice: The listing brokerage has disabled same day showing requests for this listing."}
        </div>
      )}
      <div className={css.buttonContainer}>
        {showable && (
          <Button
            type="submit"
            float="left"
            variant="outline"
            onClick={closeModalWindow}
            disabled={disabled}
          >
            Close
          </Button>
        )}
        <Button
          type="submit"
          float="right"
          variant="primary"
          onClick={handleNext}
          disabled={isHandleNextButtonDisabled}
        >
          {!showable ? "Close" : currentStep === "send" ? "Send" : "Next"}
        </Button>
        {currIndex > 0 && (
          <Button
            type="button"
            float="right"
            variant="outline"
            onClick={() => setCurrentStep(steps[currIndex - 1].id)}
            disabled={isEditDateTimeOpen}
          >
            <RiReplyFill size={14} className={css.prevIcon} />
            Previous
          </Button>
        )}
      </div>
    </div>
  );
};

export default Footer;
