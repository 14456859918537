import React, { useContext, useEffect } from "react";
import moment from "moment";
import css from "./SubHeading.sass";
import {
  getUpdatedTimes,
  filteredShowingDurations
} from "~brokerage/app/components/shared/Timeline/Main/ActiveAppointmentList/util";
import { ShowingContext } from "~brokerage/components/shared/Timeline/ShowingProvider";
import { Dropdown, Timepicker } from "~brokerage/components/shared/Form";
import Button from "~brokerage/components/shared/Button";
import {
  datetimeToObject,
  minutesToShowingDuration
} from "~brokerage/libs/helpers/TimeHelper";
import { MINUTES_STEP, THIRD_PARTY_STEP_DURATION } from "~brokerage/constants/timeline";

const TimeSelector = ({
  isEditDateTimeOpen,
  setIsEditDateTimeOpen,
  listingId,
  appointmentReason,
  isListingOwnedByCurrentUserOrOfficeTeam,
  isHandledByThirdParty
}) => {
  const { times, setTimes, setShowingDate, restrictions } =
    useContext(ShowingContext);
  const showing = times.length ? times[0] : {};
  const { duration, time } = showing;

  useEffect(() => {
    return () => {
      isEditDateTimeOpen && setIsEditDateTimeOpen(false);
    };
  }, []);

  const onTimeChange = ({ value }) => {
    const updatedTimes = getUpdatedTimes({
      duration: value,
      restrictions,
      times,
      listingId,
      isListingOwnedByCurrentUserOrOfficeTeam
    });
    setTimes(updatedTimes);
  };

  const handleTimeChange = nextDate => {
    const _time = moment(time);

    _time.hours(nextDate.hours());
    _time.minutes(nextDate.minutes());
    _time.seconds(nextDate.seconds());
    _time.milliseconds(nextDate.milliseconds);

    setShowingDate({ id: listingId, nextDate: _time });
  };

  const determineDurationOptions = () => {
    let filteredDurations = filteredShowingDurations(restrictions?.[listingId],appointmentReason);

    filteredDurations = isHandledByThirdParty ? filteredDurations.filter(duration => (duration % 15) === 0) : filteredDurations;
    return filteredDurations.map(duration => ({
      value: duration,
      label: minutesToShowingDuration(duration)
    }));
  };

  const selectedDuration = () => {
    return determineDurationOptions().find(d => d.value == duration) ||
    showingDurationOptions[0];
  };

  return (
    <div className={css.timeEditor}>
      <span> Start Time </span>
      <Timepicker
        {...datetimeToObject(moment(time))}
        onChange={handleTimeChange}
        minutesPerOption={isHandledByThirdParty ? THIRD_PARTY_STEP_DURATION : MINUTES_STEP}
      />
      <span>Select Duration</span>
      <Dropdown
        options={determineDurationOptions()}
        selected={selectedDuration().value}
        onChange={onTimeChange}
        variant="outline2"
      />
      <div>
        <Button
          float="right"
          variant="primary"
          onClick={() => setIsEditDateTimeOpen(false)}
        >
          OK
        </Button>
      </div>
    </div>
  );
};

export default TimeSelector;
