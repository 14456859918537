import css from "./Card.sass";
import React from "react";
import { formatPrice } from "~brokerage/libs/helpers/FormatHelper";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { Address, SellersTenants } from "./misc";
import { setBroadcastListingId } from "~brokerage/actions/messages";
import Actions from "../Actions";
import { Counter } from "../../shared/Counter";

const Card = ({
  listing: {
    editableByCurrentUser,
    id,
    price,
    address,
    status,
    mlsNumber,
    showings,
    messages,
    feedback,
    listingAgent,
    sellersTenants,
    showingsEnabled,
    broadcastMessageCount,
    contingencyString,
    isBuyersReportAvailable
  },
  agentId,
  dispatch,
  activeListingStatuses,
  unlimitedBroadcastMessagesAllowed
}) => {
  const history = useHistory();
  const location = useLocation();
  const isEditable = editableByCurrentUser;
  const displayShowings = isEditable && agentId != null;
  const onMarket = activeListingStatuses?.includes(status);

  const openBroadcastModal = event => {
    dispatch(setBroadcastListingId(id));
    history.push({
      pathname: location.pathname,
      query: { ...location.query, modal: "send_listing_broadcast_message" }
    });
    event.stopPropagation();
  };

  const handleEditClick = event => {
    if (isEditable) {
      history.push(`/listings/${id}/edit`);
      event.stopPropagation();
    }
  };

  const showFeedbackButton = isEditable && feedback.total > 0;

  return (
    <div className={css.cardBase}>
      <div className={css.cardInner}>
        <div className={css.actions}>
          <Actions
            canEdit={isEditable}
            showFeedback={showFeedbackButton}
            onEditClick={handleEditClick}
            onBroadCastClick={openBroadcastModal}
            listing={{
              address,
              mlsNumber,
              feedback,
              broadcastMessageCount,
              isBuyersReportAvailable,
              id
            }}
            showings={{
              approvedCount: showings.approvedCount,
              unlimitedBroadcastMessagesAllowed
            }}
            dispatch={dispatch}
          />
        </div>
        <div className={css.primaryDetails}>
          <div className={css.price}>{formatPrice(price)}</div>
          <Address addressText={address} />
        </div>
        <div className={css.secondaryDetails}>
          <div className={css.mlsNumberContainer}>
            MLS Number : <b>{mlsNumber}</b>
          </div>
          <div className={css.status}>
            <span
              className={onMarket ? css.onMarketStatus : css.offMarketStatus}
            >
              {`${status}${contingencyString}`}
            </span>
            {onMarket && (
              <span className={css.enabled}>
                {" "}
                {showingsEnabled
                  ? " · Showings Enabled"
                  : " · Showings Disabled"}
              </span>
            )}
          </div>
          {displayShowings && (
            <>
              <Counter
                count={showings.totalApproved}
                label="showing"
                suffix="s"
              />
              <b>{" · "}</b>
              <Counter count={messages.total} label="message" suffix="s" />
              <b>{" · "}</b>
              <Counter count={feedback.total} label="survey" suffix="s" />
            </>
          )}
        </div>
        <div className={css.contactData}>
          <div>
            <b>
              {listingAgent}
              {" · "}
            </b>
            <span className={css.personLabel}>{`Listing Agent`}</span>
          </div>
          <SellersTenants sellersTenants={sellersTenants} />
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { unlimitedBroadcastMessagesAllowed } = state.currentUser;
  return { unlimitedBroadcastMessagesAllowed };
}

export default connect(mapStateToProps)(Card);
