import css from "./index.sass";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { callApi } from "~brokerage/middlewares/api";
import { useHistory } from "react-router-dom";
import { SelectDate, SelectTime, SendRequest } from "./steps";
import { toast } from "react-toastify";
import ShowingProvider from "~brokerage/components/shared/Timeline/ShowingProvider";
import Footer from "./Footer";
import { steps } from "./utils";
import Heading from "./Heading";
import { DEFAULT_SHOWING_REASON } from "~brokerage/constants/appointments";
import NewBuyerModal from "./NewBuyerModal";

const ModalCreateShowing = ({
  currentUserPlatformId,
  mlsBuyerRequiredEnabled,
  mlsBuyerBrokerAgreementEnabled,
  location
}) => {
  const { listing_id } = location.query;
  const history = useHistory();

  const [currentStep, setCurrentStep] = useState(steps[0].id);
  const [listing, setListing] = useState();
  const [loading, setLoading] = useState(true);
  const [appointmentReason, setAppointmentReason] = useState(
    DEFAULT_SHOWING_REASON
  );
  const [addingNewBuyer, setAddingNewBuyer] = useState(false);
  const [buyers, setBuyers] = useState([]);
  const [selectedBuyerAgent, setSelectedBuyerAgent] = useState(null);
  const {
    showingsEnabled: { status: showable } = {},
    isOwnedByCurrentUser,
    isOfficeInvolvementTeamMember,
    isHandledByThirdParty
  } = listing || {};
  const [listingAgentAvailable, setListingAgentAvailable] = useState(false);
  const [isEditDateTimeOpen, setIsEditDateTimeOpen] = useState(false);

  useEffect(() => {
    checkListingAgentAvailability();
  }, []);

  useEffect(() => {
    if (listingAgentAvailable) {
      fetchListing();
    }
  }, [listingAgentAvailable]);

  const closeModalWindow = () => {
    history.push({
      pathname: location.pathname,
      query: { ...location.query, modal: void 0 }
    });
  };

  const fetchListing = async () => {
    try {
      const { data } = await callApi(`listings/${listing_id}`, {}, {}, "get");
      const listing = data.listing;
      setListing(listing);
    } catch (error) {
      toast.error(
        `Could not create showing for listing. Listing with listing id: ${listing_id} not found.`
      );
      closeModalWindow();
    } finally {
      setLoading(false);
    }
  };

  const checkListingAgentAvailability = async () => {
    try {
      const { data } = await callApi(
        "listings/listing_agent_availability_status",
        { id: listing_id },
        {},
        "get"
      );
      const { listing_agent_available: listingAgentAvailable } = data.data;

      setListingAgentAvailable(listingAgentAvailable);
      if (!listingAgentAvailable) {
        alert(
          "Showing cannot be added for the listing as it does not have a valid listing agent. Check back later or contact the LISTING BROKER directly."
        );
      }
    } catch {
      toast.error("Something went wrong.");
    }
  };

  if (loading) {
    return <></>;
  }

  let content = <></>;

  switch (currentStep) {
    case "date":
      content = (
        <SelectDate
          listing={listing}
          appointmentReason={appointmentReason}
          setAppointmentReason={setAppointmentReason}
          setSelectedBuyerAgent={setSelectedBuyerAgent}
          selectedBuyerAgent={selectedBuyerAgent}
        />
      );
      break;
    case "time":
      content = (
        <SelectTime
          showListingRestrictions={true}
          listing={listing}
          appointmentReason={appointmentReason}
        />
      );
      break;
    case "send":
      content = (
        <SendRequest
          listing={listing}
          mlsBuyerRequiredEnabled={mlsBuyerRequiredEnabled}
          mlsBuyerBrokerAgreementEnabled={mlsBuyerBrokerAgreementEnabled}
          appointmentReason={appointmentReason}
          buyers={buyers}
          setBuyers={setBuyers}
          createBuyer={() => setAddingNewBuyer(true)}
          buyerAgentId={selectedBuyerAgent?.id}
        />
      );
      break;
    default:
      break;
  }

  const currIndex = steps.findIndex(s => s.id === currentStep);
  const isListingOwnedByCurrentUserOrOfficeTeam =
    isOwnedByCurrentUser || isOfficeInvolvementTeamMember;

  return (
    <ShowingProvider>
      <div className={css.base}>
        <Heading
          currIndex={currIndex}
          steps={steps}
          currentStep={currentStep}
          showable={showable}
          listingId={listing.id}
          isEditDateTimeOpen={isEditDateTimeOpen}
          setIsEditDateTimeOpen={setIsEditDateTimeOpen}
          appointmentReason={appointmentReason}
          isListingOwnedByCurrentUserOrOfficeTeam={
            isListingOwnedByCurrentUserOrOfficeTeam
          }
          isHandledByThirdParty={isHandledByThirdParty}
        />
        <div className={css.content}>{content}</div>
        <Footer
          closeModalWindow={closeModalWindow}
          showable={showable}
          address={listing?.address}
          currIndex={currIndex}
          currentStep={currentStep}
          steps={steps}
          setCurrentStep={setCurrentStep}
          advanceNoticeDuration={listing?.advanceNoticeDuration}
          disableSameDayRequests={listing?.disableSameDayRequests}
          appointmentReason={appointmentReason}
          buyers={buyers}
          buyerAgentPlatformId={
            selectedBuyerAgent?.platformId || currentUserPlatformId
          }
          isBuyerNameRequired={
            mlsBuyerRequiredEnabled && listing?.buyerNameRequired
          }
          selectedBuyerAgent={selectedBuyerAgent}
          isEditDateTimeOpen={isEditDateTimeOpen}
          mlsBuyerBrokerAgreementEnabled={mlsBuyerBrokerAgreementEnabled}
        />
      </div>
      <NewBuyerModal
        isOpen={addingNewBuyer}
        closeModal={() => setAddingNewBuyer(false)}
        onAddBuyer={newBuyer => setBuyers([...buyers, newBuyer])}
      />
    </ShowingProvider>
  );
};

const mapStateToProps = ({ currentUser }) => {
  const {
    platformId: currentUserPlatformId,
    mlsBuyerRequiredEnabled,
    mlsBuyerBrokerAgreementEnabled
  } = currentUser;
  return {
    currentUserPlatformId,
    mlsBuyerRequiredEnabled,
    mlsBuyerBrokerAgreementEnabled
  };
};

export default connect(mapStateToProps)(ModalCreateShowing);
