import css from "./Header.sass";
import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Button from "~brokerage/components/shared/Button";
import Badge from "~brokerage/components/shared/Badge";
import Icon from "~brokerage/components/shared/Icon";
import {
  propertySearch,
  setProperties
} from "~brokerage/actions/propertySearch";
import { Form, TextField } from "~brokerage/components/shared/Form";
import { setSelectedProperties } from "~brokerage/actions/propertySearch";
import { connect } from "react-redux";
import { variants } from "./variants";
import { callApi } from "~brokerage/middlewares/api";
import { MAX_SELECTIONS } from "~brokerage/constants/propertySearch/userSelections";
import { toast } from "react-toastify";

const Header = ({
  location,
  dispatch,
  selections,
  allowedOnly,
  variant = "route",
  activeListingStatuses
}) => {
  const formRef = useRef();
  const [query, setQuery] = useState(location.query["query"] || "");
  const history = useHistory();

  const { modal, buttonLabel, showBadge } = variants[variant];

  const onMarketStatuses = activeListingStatuses?.toString();

  useEffect(() => {
    if (location.query["mlsNumbers"]) {
      autoSearch(location.query["mlsNumbers"]);
    } else {
      handleSearchSubmit();
    }
  }, [onMarketStatuses]);

  // When a route is requested via SSO, url should contain param mlsNumbers.
  // When mlsNumbers is present, request all properties, select them, and prompt route creation
  const autoSearch = ids => {
    callApi(
      "my_organization/listings/search",
      { "filter[mls_numbers]": ids },
      {},
      "get"
    )
      .then(({ data: { listings } }) => {
        dispatch(setProperties(listings));
        dispatch(setSelectedProperties(listings.slice(0, MAX_SELECTIONS)));
        alertForMissingListings(
          new Set(ids.split(",")),
          new Set(listings.map(l => l.mlsNumber))
        );
        if (listings.length > MAX_SELECTIONS) {
          history.push({
            pathname: location.pathname,
            query: {
              ...location.query,
              modal: "max_selected"
            }
          });
        } else {
          handleAddToRoute();
        }
      })
      .catch(err => {
        toast.error(err.message || "Unknown Message");
      });
  };

  const alertForMissingListings = (targetIds, obtainedIds) => {
    if (targetIds.size > obtainedIds.size) {
      const missingIds = [...targetIds].filter(id => !obtainedIds.has(id));
      toast.error(
        `The listings ${missingIds.join(", ")} could not be added to route.`
      );
    }
  };

  const handleSearchSubmit = () => {
    history.push({
      pathname: location.pathname,
      query: {
        property_statuses: onMarketStatuses,
        query: query || undefined,
        mlsNumbers: location.query["mlsNumbers"] || undefined,
        modal: location.query.modal || undefined,
        listing_id: location.query.listing_id || undefined
      }
    });

    // TODO: filter updates
    dispatch(
      propertySearch(
        {
          property_statuses: onMarketStatuses
        },
        1,
        query || "",
        allowedOnly,
        variant
      )
    );
  };

  const handleChange = e => {
    setQuery(e.target.value);
  };

  const handleAddToRoute = () => {
    history.push({
      pathname: location.pathname,
      query: {
        ...location.query,
        modal,
        listing_id: modal === "create_showing" ? selections[0].id : void 0
      }
    });
  };

  return (
    <div className={css.layout}>
      <Form onSubmit={handleSearchSubmit} ref={formRef}>
        <div className={css.search}>
          <TextField
            variant="headerSearch"
            name="query"
            type="search"
            placeholder="Search Address or  MLS #"
            autoComplete="off"
            value={query}
            onChange={handleChange}
          />
          <Button variant="search" type="submit">
            <Icon name="search" />
          </Button>
        </div>
      </Form>
      <div className={css.right}>
        <Button
          variant="green"
          modifer="textAlignCenter"
          disabled={!selections.length}
          onClick={handleAddToRoute}
        >
          <Icon name="plus" />
          {buttonLabel}
          {showBadge && selections.length > 0 && (
            <Badge variant="orange">{selections.length}</Badge>
          )}
        </Button>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const selections = state.propertySearch.selected.listings;
  const { allowedOnly } = state.propertySearch.list.listings;
  const { activeListingStatuses } = state.currentUser;
  return {
    selections,
    allowedOnly,
    activeListingStatuses
  };
}

export default connect(mapStateToProps)(Header);
