import { pixelsToMinutes } from "~brokerage/libs/helpers/TimeHelper";
import { PIXELS_PER_MINUTE, MINUTES_STEP, THIRD_PARTY_STEP_DURATION } from "~brokerage/constants/timeline";

const getNextDate = (container, topDisregardingContainer, minDate, isHandledByThirdParty = false) => {
  const containerTop = container.getBoundingClientRect().top;
  let top = topDisregardingContainer - containerTop + container.scrollTop;
  const minutesPerStep = isHandledByThirdParty ? THIRD_PARTY_STEP_DURATION : MINUTES_STEP;
  top = top - (top % (minutesPerStep * PIXELS_PER_MINUTE));
  const dayOffsetMinutes = pixelsToMinutes(top);
  return minDate.clone().add(dayOffsetMinutes, "minutes");
};

export default getNextDate;
