import css from "./index.sass";
import React, { useEffect, useState, useContext } from "react";
import Timeline from "~brokerage/components/shared/Timeline";
import { ShowingContext } from "~brokerage/components/shared/Timeline/ShowingProvider";
import { callApi } from "~brokerage/middlewares/api";
import { toast } from "react-toastify";
import moment from "moment";
import { getTimes, getOpenSlots, findIdealSlot } from "./utils";
import { DEFAULT_SHOWING_DURATION } from "~brokerage/constants/showings";

const SelectTime = ({
  listing: {
    id,
    isOwnedByCurrentUser,
    isOfficeInvolvementTeamMember,
    address,
    photoUrl,
    isHandledByThirdParty
  },
  showListingRestrictions,
  appointmentReason
}) => {
  const [staticAppointments, setStaticAppointments] = useState([]);
  const [loading, setLoading] = useState(true);
  const { times, setShowingDate } = useContext(ShowingContext);
  const activeShowing = times.find(t => t.id === id) || {};
  let { startTime, duration } = activeShowing;
  const [initialTime, setInitialTime] = useState(startTime);
  const [isMounted, setMounted] = useState(false);
  if (!duration) duration = DEFAULT_SHOWING_DURATION;

  useEffect(() => {
    !isMounted && setMounted(true);
    toast.info("Drag bar to change the appointment time", {
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      closeButton: false,
      toastId: "dragHint"
    });
    return () => {
      setShowingDate(null);
      setMounted(false);
    };
  }, []);

  useEffect(() => {
    isMounted && fetchStaticShowings();
  }, [isMounted]);

  useEffect(() => {
    getInitialTime(startTime);
  }, [staticAppointments]);

  const fetchStaticShowings = async () => {
    try {
      const { data } = await callApi(
        "showings",
        {
          date: moment(startTime).format("DD-MM-YYYY"),
          limit: 100
        },
        {},
        "get"
      );
      isMounted && setStaticAppointments(data.showings);
    } catch (error) {
      toast.error("Failed to get appointments.");
    } finally {
      isMounted && setLoading(false);
    }
  };

  const getInitialTime = date => {
    if (staticAppointments.length) {
      const appointments = getTimes(staticAppointments);
      const openSlots = getOpenSlots(appointments);
      const bestStartTime = findIdealSlot(openSlots, ["10:00", "10:30"], date);

      setInitialTime(bestStartTime);
    } else {
      if (moment(date).isSame(moment(), "day")) {
        const now = moment();
        setInitialTime(
          now.set("hour", now.hour()).add(1, "hour").startOf("hour")
        );
      } else {
        setInitialTime(moment(date).set("hour", 10).format());
      }
    }
  };

  const appt = {
    id: id,
    position: 1,
    listingKey: id,
    requestedTimeRaw: initialTime,
    address: address,
    photoUrl: photoUrl,
    isHandledByThirdParty: isHandledByThirdParty,
    duration
  };

  const isListingOwnedByCurrentUserOrOfficeTeam =
    isOwnedByCurrentUser || isOfficeInvolvementTeamMember;

  return (
    <div className={css.timeline}>
      {!loading && (
        <Timeline
          isListingOwnedByCurrentUserOrOfficeTeam={
            isListingOwnedByCurrentUserOrOfficeTeam
          }
          initiallyActiveListing={appt.id}
          showRestrictions={showListingRestrictions}
          staticAppointments={staticAppointments}
          activeAppointments={[appt]}
          maxHeight={400}
          appointmentReason={appointmentReason}
        />
      )}
    </div>
  );
};

export default SelectTime;
