import css from "./Showing.sass";
import React, { useContext } from "react";
import { composeClassName } from "~brokerage/libs/helpers/ClassNameHelper";
import {
  RiArrowDropDownFill,
  RiRoadsterFill,
  RiDragMoveFill,
  RiCheckLine
} from "@remixicon/react";
import { HIDE_TRAVEL_TIME_THRESHOLD } from "~brokerage/constants/showings";
import { Menu, MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import { ShowingContext } from "~brokerage/components/shared/Timeline/ShowingProvider";
import {
  STATUS_CANCELLED,
  STATUS_DECLINED
} from "~brokerage/app/constants/showings/statuses";
import {
  determineConflicts,
  filteredShowingDurations
} from "./ActiveAppointmentList/util";
import {
  NON_SHOWING_APPOINTMENT_REASONS,
  MINUTES_DISPLAY_THRESHOLD,
  MINUTES_PER_HOUR
} from "~brokerage/constants/showings";
import { minutesToShowingDuration } from "~brokerage/libs/helpers/TimeHelper.js";
import { THIRD_PARTY_STEP_DURATION } from "~brokerage/app/constants/timeline";

const Showing = ({
  id,
  address,
  status,
  duration,
  disabled,
  travelTime,
  displayTime,
  conflicts,
  restrictions,
  appointmentReason,
  isListingOwnedByCurrentUserOrOfficeTeam,
  isHandledByThirdParty,
  zIndex = null,
}) => {
  const roundedTravelTime = Math.round(travelTime / 5) * 5;
  const modifier = conflicts;
  const { times, setTimes } = useContext(ShowingContext);
  const disableDuration =
    disabled || [STATUS_CANCELLED, STATUS_DECLINED].includes(status);

  const setDuration = dur => {
    const { unavailability = [], allowOverlap = false } = restrictions ?? {};
    const update = [...times].map(s => {
      return s.id === id
        ? {
            ...s,
            duration: dur,
            conflicts: determineConflicts({
              unavailability,
              nextDate: s.time,
              duration: dur,
              showingId: id,
              allowOverlap,
              isListingOwnedByCurrentUserOrOfficeTeam
            }),
            modified: true
          }
        : s;
    });
    setTimes(update);
  };

  if (
    restrictions &&
    (restrictions.maxDuration ?? 180) < duration &&
    !NON_SHOWING_APPOINTMENT_REASONS.includes(appointmentReason)
  )
    setDuration(restrictions.maxDuration);

  const showingDurationOptions = () => {
    const options = filteredShowingDurations(restrictions, appointmentReason);
    const filteredOptions = isHandledByThirdParty ? options.filter(option => option % THIRD_PARTY_STEP_DURATION === 0) : options;
    return filteredOptions;
  };

  const renderDuration = duration => {
    if (duration > MINUTES_DISPLAY_THRESHOLD) {
      return `${duration / MINUTES_PER_HOUR} hours`;
    } else {
      return `${duration} mins`;
    }
  };

  return (
    <>
      {travelTime > 0 && (
        <div
          style={{
            marginTop: `-${roundedTravelTime}px`,
            height: `${roundedTravelTime}px`
          }}
          className={composeClassName(css, "travelTime")}
        >
          {travelTime >= HIDE_TRAVEL_TIME_THRESHOLD && (
            <>
              <div className={css.icon}>
                <RiRoadsterFill size={14} />
                <span>Travel Time</span>
              </div>
              <div className={css.time}>{travelTime} mins</div>
            </>
          )}
        </div>
      )}
      <div
        className={
          disabled
            ? composeClassName(css, "disabled", `duration${duration}`)
            : composeClassName(
                css,
                "base",
                `duration${duration} ${status}Status ${modifier}`
              )
        }
        style={zIndex != null ? { zIndex } : {}}
      >
        <div className={css.addressContainer}>
          {!disabled && (
            <RiDragMoveFill
              className={composeClassName(
                css,
                "dragIcon",
                `dragIcon${modifier}`
              )}
              size={16}
            />
          )}
          {!!displayTime && (
            <div className={css.displayTime}>{displayTime}</div>
          )}
          <div className={css.address}>{address}</div>
        </div>
        <div
          className={css.duration}
          data-ignoretouch={true}
          onMouseDown={e => e.stopPropagation()}
        >
          {disableDuration ? (
            <div className={css.button}>{renderDuration(duration)}</div>
          ) : (
            <Menu
              direction="bottom"
              align="end"
              position="auto"
              viewScroll="auto"
              className={css.menu}
              menuButton={
                <div className={css.button}>
                  {renderDuration(duration)} <RiArrowDropDownFill size={15} />
                </div>
              }
            >
              {showingDurationOptions().map(d => {
                const active = duration === d;
                const modifier = active ? "activeItem" : "";
                return (
                  <MenuItem
                    key={`duration-${d}`}
                    className={composeClassName(css, "menuItem", modifier)}
                    onClick={e => setDuration(e.value)}
                    value={d}
                  >
                    <span>{minutesToShowingDuration(d)}</span>
                    {active && <RiCheckLine className={css.icon} size={18} />}
                  </MenuItem>
                );
              })}
            </Menu>
          )}
        </div>
      </div>
    </>
  );
};

export default Showing;
