import * as types from "~brokerage/constants/actionTypes";
import api from "./api";

export function setSelectedProperties(update) {
  return {
    type: types.PROPERTY_SEARCH_UPDATE_SELECTED,
    update
  };
}

export function propertySearch(
  filter,
  page,
  query,
  enabled_only,
  variant = ""
) {
  return api.myOrganization.propertySearch(
    filter,
    page,
    query,
    enabled_only,
    variant,
    [
      types.PROPERTY_SEARCH_REQUEST,
      types.PROPERTY_SEARCH_SUCCESS,
      types.PROPERTY_SEARCH_FAILURE
    ]
  );
}

export function setProperties(properties) {
  return {
    type: types.PROPERTY_SEARCH_SUCCESS,
    data: {
      listings: properties,
      listingsCount: properties.length,
      page: 1,
      totalPages: 1
    }
  };
}

export function toggleShowAllowedProperties() {
  return {
    type: types.PROPERTY_TOGGLE_SHOW_ALLOWED
  };
}
