import React from "react";

const counterText = count => {
  if (count === 0) {
    return "No";
  } else if (count > 99) {
    return "99+";
  } else {
    return `${count}`;
  }
};

export const Counter = ({ count, label, suffix }) => {
  const labelText = count == 1 ? label : label + suffix;

  return (
    <span>
      <b>{counterText(count)}</b> {labelText}
    </span>
  );
};
